import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditReceiptAddressInput,
  EditReceiptAddressMutation,
  EditReceiptAddressMutationVariables,
} from "../generated/graphql";

export interface IEditReceiptAddressFormProps extends EditReceiptAddressInput {}
const EDIT_RECEIPT_ADDRESS_MUTATION = gql`
  mutation editReceiptAddress($input: EditReceiptAddressInput!) {
    editReceiptAddress(input: $input) {
      ok
      err
    }
  }
`;

export const useEditReceiptAddress = (
  onCompleted?: (data: EditReceiptAddressMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditReceiptAddressMutation,
    EditReceiptAddressMutationVariables
  >(EDIT_RECEIPT_ADDRESS_MUTATION, {
    onCompleted,
    onError,
  });
};
