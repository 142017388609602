import React, { useState } from "react";
import { Link } from "react-router-dom";
import searchImg from "../assets/icon/search.png";
import { useForm } from "react-hook-form";
import { ComponentTabOrder } from "./tabs/component-tab-order";
import { ComponentTabRelease } from "./tabs/component-tab-release";
import { ComponentTabDelivery } from "./tabs/component-tab-delivery";
import { ComponentTabStart } from "./tabs/component-tab-start";
import { ComponentTabEnd } from "./tabs/component-tab-end";
import { ComponentTabExchange } from "./tabs/component-tab-exchange";
import { ComponentTabConfirm } from "./tabs/component-tab-confirm";
import { ComponentTabProduct } from "./tabs/component-tab-product";
import { ComponentTabBound } from "./tabs/component-tab-bound";
import { ComponentTabFlight } from "./tabs/component-tab-flight";
import { ComponentTabDrone } from "./tabs/component-tab-drone";
import { ComponentTabProject } from "./tabs/component-tab-project";
import { ComponentTabPort } from "./tabs/component-tab-port";
import { ComponentTabDoor } from "./tabs/component-tab-door";
import { ComponentTabCorporation } from "./tabs/component-tab-corporation";
import { ComponentTabEvent } from "./tabs/component-tab-event";
import { ComponentTabService } from "./tabs/component-tab-service";
import { ComponentTabReceipt } from "./tabs/component-tab-receipt";

export interface ITabLink {
  text: string;
  to: string;
  selected?: boolean;
}

export interface ITabTitle {
  text: string;
}
export enum PageType {
  Receipt = "Receipt",
  Delivery = "Delivery",
  Product = "Product",
  Order = "Order",
  Release = "Release",
  Exchange = "Exchange",
  Confirm = "Confirm",
  Bound = "Bound",
  Rproduct = "Rproduct",
  Flight = "Flight",
  Drone = "Drone",
  Payments = "Payments",
  Create = "Create",
  Corporation = "Corporation",
  Project = "Project",
  Port = "Port",
  Start = "Start",
  End = "End",
  Door = "Door",
  Event = "Event",
  Pack = "Pack",
  Service = "Service",
}
export interface ISearchFormProps {
  search: string;
}
export interface IComponentTabLinkProps {
  tabs: ITabLink[];
  stitle: ITabTitle;
  type?: PageType;
  dataList?: any[];
  setSearchs?: (search: string) => void;
  setDateSearch?: (sDate?: Date, eDate?: Date) => void;
  setCreate?: any;
  onClose?: () => void;
  refetch?(): void;
}

export const ComponentTabLink: React.FC<IComponentTabLinkProps> = ({
  tabs,
  stitle,
  type,
  setSearchs,
  setCreate,
  dataList,
  onClose,
  refetch,
  setDateSearch,
}) => {
  // -- 검색 ---

  const {
    register: sregister,
    getValues: sGetValues,
    handleSubmit: sHandleSubmit,
  } = useForm<ISearchFormProps>({ mode: "all" });
  const OnSearch = async () => {
    const { search } = sGetValues();
    if (setSearchs) {
      setSearchs(search);
    }
  };

  return (
    <div className="w-full">
      <div className="">
        <div className="grid grid-cols-[300px_1fr_200px]">
          <div className="flex flex-wrap">
            <div className="border-l-4 border-[#3D3E49] my-2 ml-2 w-4 h-5"></div>
            <h1 className="inline-block font-bold mb-2 text-[24px] text-[#3D3E49]">
              {stitle.text}
            </h1>
          </div>
          <div className="subtitle h-full flex flex-wrap content-end">
            {tabs.map(({ selected, text, to }, index) => (
              <div
                key={`tab-${index}`}
                className={`inline-block w-[104px] py-2 text-center rounded-t-xl bg-white ${
                  selected ? `bg-[#3D3E49]` : ``
                }`}
              >
                <Link
                  className={`block leading-normal font-bold hover:opacity-60 w-full ${
                    selected ? `text-white` : `text-[#4F4F4F]`
                  }`}
                  to={to}
                >
                  {`${text}`}
                </Link>
              </div>
            ))}
          </div>
          {type !== PageType.Create && (
            <div className="text-right">
              <form onSubmit={sHandleSubmit(OnSearch)}>
                <p className="inline-block max-w-md w-[200px] text-right relative">
                  <input
                    {...sregister(`search`)}
                    type="text"
                    className="w-full py-1 px-4 bg-white rounded-full"
                    placeholder={`${
                      type === PageType.Order ? "수취인,주소,연락처" : ""
                    }`}
                  />

                  <button className="absolute right-3 top-1">
                    <img src={searchImg} alt="찾기" className="w-5 h-5" />
                  </button>
                </p>
              </form>
            </div>
          )}
        </div>
        <div className="h-14 w-full rounded-t-2xl bg-[#3D3E49] ">
          {type === PageType.Receipt && (
            <ComponentTabReceipt
              dataList={dataList}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Order && (
            <ComponentTabOrder
              dataList={dataList}
              refetch={refetch}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Release && (
            <ComponentTabRelease
              dataList={dataList}
              refetch={refetch}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Delivery && (
            <ComponentTabDelivery
              dataList={dataList}
              refetch={refetch}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Start && (
            <ComponentTabStart
              dataList={dataList}
              refetch={refetch}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.End && (
            <ComponentTabEnd
              dataList={dataList}
              refetch={refetch}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Exchange && (
            <ComponentTabExchange
              dataList={dataList}
              refetch={refetch}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Confirm && (
            <ComponentTabConfirm
              dataList={dataList}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Product && (
            <ComponentTabProduct dataList={dataList} refetch={refetch} />
          )}
          {type === PageType.Bound && (
            <ComponentTabBound
              dataList={dataList}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Flight && (
            <ComponentTabFlight
              dataList={dataList}
              setDateSearch={setDateSearch}
            />
          )}
          {type === PageType.Drone && <ComponentTabDrone dataList={dataList} />}
          {type === PageType.Project && (
            <ComponentTabProject dataList={dataList} />
          )}
          {type === PageType.Port && <ComponentTabPort dataList={dataList} />}
          {type === PageType.Door && (
            <ComponentTabDoor setDateSearch={setDateSearch} />
          )}
          {type === PageType.Corporation && <ComponentTabCorporation />}
          {type === PageType.Service && <ComponentTabService />}
          {type === PageType.Event && <ComponentTabEvent />}
          {type === PageType.Create && (
            <div className="flex flex-wrap justify-end pr-8">
              <div className="flex flex-wrap gap-x-3">
                <button
                  form="create"
                  // onClick={() => testHandler()}
                  className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white"
                >
                  등록
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
